import { call, put, takeLatest, select } from 'redux-saga/effects'
const HttpsProxyAgent = require('https-proxy-agent');
const httpmakeReq = require('./httpPromise');
const httpsmakeReq = require('./httpsPromise');
const fs =  require('fs');
const remoteHost = "sit-fintech.hangseng.com";
const remotePort = 443;
const localHost = "localhost";
const localPort = 4201;
/*
  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
    //yield takeLatest("SUBMIT_PAYMENT", payment);
    yield takeLatest("PAYMENT_FULFILMENT", paymentFulfilment);
  }


function* payment() {
    try {
      const checkMobile = yield select(state => state.transfer.checkMobile);
      const transfer_data = yield select(state => state.transfer.transfer_data);
      let data = JSON.parse(yield call(submit_payment, transfer_data));
      //yield put({type: "SUBMIT_PAYMENT_SUCCEEDED", response: data});
      yield call(()=>{
         if(data)
         {
            if(checkMobile)
               window.location.href = "haserbapp://open/?key=open_banking&redirectJwt=" + new URL(data.redirectUrl).searchParams.get('redirectJwt');
            else
               window.location.href = data.redirectUrl;
         }
        else
          throw "error";
      });
    } catch (e) {
      console.log(e.message);
       yield put({type: "SUBMIT_PAYMENT_FAILED", payload: e.message});
    }
}

function submit_payment(transfer_data){
   let options = {
      host: remoteHost,
      port: remotePort,
      method: 'POST',
      path: '/payment',
      rejectUnauthorized: false,
      headers: {
          'Content-Type': 'application/json'
        }
  };

  let data = JSON.stringify({...transfer_data});
  console.log(data)
  return httpmakeReq(options, data)
  .then((res) => { 
    console.log("result:");
    console.log(res);
    return res.body;
  })
  .catch((err) => { 
    console.log(err)
    throw err;
  });
   /*return axios({
      method: 'post',
      url: 'https://nginx-fulfilment-hase-nlb-512dbb32f197e5c8.elb.ap-east-1.amazonaws.com/payment',
      data: {...action.transfer_data},
      agent: agent
    })*/
}

function* paymentFulfilment(fulfilment_data) {
    try {
        let data = JSON.parse(yield call(submit_paymentFulfilment, fulfilment_data));
        yield put({type: "SUBMIT_PAYMENT_SUCCEEDED", payload: data});
    } catch (e) {
        console.log(e);
        yield put({type: "SUBMIT_PAYMENT_FAILED", payload: "There is error: " + e.body});
    }
}

function submit_paymentFulfilment(fulfilment_data){
    let options = {
        host: remoteHost,
        port: remotePort,
        method: 'POST',
        path: '/paymentfulfilment',
        rejectUnauthorized: false,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let data = JSON.stringify({...fulfilment_data.payload});

    return httpmakeReq(options, data)
        .then((res) => {
            console.log("result:");
            console.log(res);
            return res.body;
        })
        .catch((err) => {
            throw err;
        });
}

export default mySaga;
