import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import { Link } from "react-router-dom";
import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  });

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vertical: 'top',
            horizontal: 'center',
        };
        this.redirectToASPSP = this.redirectToASPSP.bind(this);
    }
    
    redirectToASPSP()
    {
        this.props.dispatch({type: 'SHOW_BACKDROP', payload:true});
        this.props.dispatch({type: 'SUBMIT_PAYMENT'})
    }

    render()
    {
        const { classes } = this.props;
        const { vertical, horizontal } = this.state;
        return (
               
            <form action="https://sit-fintech.hangseng.com/payment" method="post">
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={this.props.showError}
                    message={"There is error: " + this.props.message}
                    key={vertical + horizontal}
                />
                <Backdrop className={classes.backdrop} open={this.props.showBackDrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Payment total</td>
                                <td>{this.props.transfer_data.Data.Initiation.InstructedAmount.Currency} {sessionStorage.getItem('price')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>To consent to this transaction, check the details below</h4>
                    <div>
                        <p>Payee information</p>
                        <table className="payee_info">
                            <tbody>
                                <tr>
                                    <td>Account number:</td>
                                    <td>{this.props.transfer_data.Data.Initiation.CreditorAccount.Identification}</td>
                                </tr>
                                <tr>
                                    <td>Remark:</td>
                                    <td>{this.props.transfer_data.Data.Initiation.RemittanceInformation.Reference}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4>You will be securely transferred to YOUR ASPSP to authenticate and make the payment</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td className="text-center">
                                    <Link to='/'>
                                        <Button variant="contained">Back</Button>
                                    </Link>
                                </td>
                                <td className="text-center">
                                    <Button variant="contained" type="submit" onClick={()=> this.props.dispatch({type: 'SHOW_BACKDROP', payload:true})}>Pay</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <input type="hidden" id="transfer_data" name="transfer_data" value={JSON.stringify(this.props.transfer_data)} />
                <input type="hidden" id="checkMobile" name="checkMobile" value={JSON.stringify(this.props.checkMobile)} />
            </form>
        )
    };
}

const mapStateToProps = (state) => {
    return {...state.transfer, ...state.showBackDrop, ...state.message};
}

export default withStyles(useStyles)(connect(mapStateToProps)(Confirm));
